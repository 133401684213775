// Primary Colors
$armColor: #016db5;
$armSecondaryColor: #143753;
$armTheme: #016db5;
$armAnchor: #224179;

// Shadows
$armShadow: 0 5px 10px 0 rgba(201, 200, 200, 0.32);
$armShadowHover: 0 5px 20px 0 rgba(201, 200, 200, 0.32);

// Border Radius
$armCardRadius: 10px;

// Additional Colors
$armSuccessColor: #52c41a; // Success state color
$armWarningColor: #faad14; // Warning state color
$armErrorColor: #f5222d; // Error state color
$armLightGray: #f2f2f2;
$armMediumGray: #d0d0d0;
$armDarkGray: #595959;
$armWhite: #ffffff;
$armBlack: #000000;

// Font Sizes
$font-size-base: 14px; // Major text font size
$font-size-small: 12px; // Small text font size
$font-size-large: 16px; // Large text font size

// Spacing
$spacing-small: 8px;
$spacing-medium: 16px;
$spacing-large: 24px;

// Z-Index
$z-index-dropdown: 1000;
$z-index-modal: 1050;
$z-index-tooltip: 1070;

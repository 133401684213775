/* You can add global styles to this file, and also import other style files */
/* Jan 25 */
@import "variables";

@font-face {
  font-family: "Montserrat";
  src: url("assets/webfonts/Montserrat-Bold.eot");
  src: url("assets/webfonts/Montserrat-Bold.eot?#iefix") format("embedded-opentype"),
       url("assets/webfonts/Montserrat-Bold.woff2") format("woff2"),
       url("assets/webfonts/Montserrat-Bold.woff") format("woff"),
       url("assets/webfonts/Montserrat-Bold.ttf") format("truetype"),
       url("assets/webfonts/Montserrat-Bold.svg#Montserrat-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("assets/webfonts/Montserrat-Regular.eot");
  src: url("assets/webfonts/Montserrat-Regular.eot?#iefix") format("embedded-opentype"),
       url("assets/webfonts/Montserrat-Regular.woff2") format("woff2"),
       url("assets/webfonts/Montserrat-Regular.woff") format("woff"),
       url("assets/webfonts/Montserrat-Regular.ttf") format("truetype"),
       url("assets/webfonts/Montserrat-Regular.svg#Montserrat-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("assets/webfonts/Montserrat-Medium.eot");
  src: url("assets/webfonts/Montserrat-Medium.eot?#iefix") format("embedded-opentype"),
       url("assets/webfonts/Montserrat-Medium.woff2") format("woff2"),
       url("assets/webfonts/Montserrat-Medium.woff") format("woff"),
       url("assets/webfonts/Montserrat-Medium.ttf") format("truetype"),
       url("assets/webfonts/Montserrat-Medium.svg#Montserrat-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("assets/webfonts/Montserrat-SemiBold.eot");
  src: url("assets/webfonts/Montserrat-SemiBold.eot?#iefix") format("embedded-opentype"),
       url("assets/webfonts/Montserrat-SemiBold.woff2") format("woff2"),
       url("assets/webfonts/Montserrat-SemiBold.woff") format("woff"),
       url("assets/webfonts/Montserrat-SemiBold.ttf") format("truetype"),
       url("assets/webfonts/Montserrat-SemiBold.svg#Montserrat-SemiBold") format("svg");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

body {
  font-family: "Montserrat", Arial, sans-serif; // Added fallback fonts
  font-size: 14px;
  letter-spacing: 0.5px;
}

a {
  color: $armAnchor;
}

.ant-card {
  border-radius: $armCardRadius !important;
  box-shadow: $armShadow;
  .ant-card-actions {
    border-radius: 0 0 $armCardRadius $armCardRadius !important;
  }
  .ant-card-head {
    border-radius: $armCardRadius $armCardRadius 0 0 !important;
    background: rgb(220 239 245);
    .ant-card-extra {
      a {
        color: $armSecondaryColor;
      }
    }
    .ant-card-head-title {
      font-weight: bold;
    }
  }
}

.ant-table {
  line-height: 0.5715;
}

.ant-card:hover {
  box-shadow: $armShadowHover;
}

.ant-card.no-color {
  .ant-card-head {
    background: #ffffff;
  }
}

.ant-collapse {
  border-radius: $armCardRadius !important;
  box-shadow: $armShadow;
  .ant-collapse-actions {
    border-radius: 0 0 $armCardRadius $armCardRadius !important;
  }
  .ant-collapse-header {
    background: rgb(247 121 36 / 40%);
    .ant-collapse-extra {
      a {
        color: $armSecondaryColor;
      }
    }
    font-weight: bold;
  }
}

.ant-collapse:hover {
  box-shadow: $armShadowHover;
}

.ant-collapse.no-color {
  .ant-collapse-head {
    background: #ffffff;
  }
}

.arm-shadow {
  box-shadow: $armShadow;
}

.arm-shadow:hover {
  box-shadow: $armShadowHover;
}

/* End Jan 25 */

.topheadingifbutton {
  position: relative;
  margin-bottom: 20px;
  clear: both;
  overflow: hidden;
  border-bottom: 1px solid #e2e2e2;
  padding-bottom: 15px;
  h3 {
    float: left;
    font-size: 22px;
    letter-spacing: 0.2px;
  }
}

//status active/in-active
.active {
  background-color: green;
}

.inactive {
  background-color: red;
}

.status {
  width: 12px;
  height: 12px;
  display: block;
  margin-left: 5px;
}

// Dynamic Margin & Padding class:
$sizes: 12;

@mixin margin-classes {
  @for $i from 1 through $sizes {
    $margin: $i * 0.25rem;
    .m#{$i} {
      margin: $margin;
    }
    .ml#{$i} {
      margin-left: $margin;
    }
    .mr#{$i} {
      margin-right: $margin;
    }
    .mt#{$i} {
      margin-top: $margin;
    }
    .mb#{$i} {
      margin-bottom: $margin;
    }
    .mx#{$i} {
      margin-left: $margin;
      margin-right: $margin;
    }
    .my#{$i} {
      margin-top: $margin;
      margin-bottom: $margin;
    }
  }
}
@include margin-classes;

@mixin padding-classes {
  @for $i from 1 through $sizes {
    $padding: $i * 0.25rem;
    .p#{$i} {
      padding: $padding;
    }
    .pl#{$i} {
      padding-left: $padding;
    }
    .pr#{$i} {
      padding-right: $padding;
    }
    .pt#{$i} {
      padding-top: $padding;
    }
    .pb#{$i} {
      padding-bottom: $padding;
    }
    .px#{$i} {
      padding-left: $padding;
      padding-right: $padding;
    }
    .py#{$i} {
      padding-top: $padding;
      padding-bottom: $padding;
    }
  }
}
@include padding-classes;

.addUpdateButton {
  float: right;
}

.ant-tag-checkable {
  border: 1px solid;
}

:root {
  --color-base: #000;
  --color-theme: #000000;
  --color-theme1: #035858;
  --color-theme-hover: #333333; // Added hover color for theme
  --color-lightgray: #f2f2f2;
  --color-mideumgray: #d0d0d0;
  --color-white: #fff;
}

.app-layout {
  background-color: #f9f9f9;
  min-height: 100vh;
}

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border: none;
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  line-height: 1.6;
}

.ant-menu-inline .ant-menu-item-group-list .ant-menu-submenu-title,
.ant-menu-inline .ant-menu-submenu-title {
  padding-left: 0px !important;
}

.ant-menu-sub.ant-menu-inline {
  background: transparent !important;
}

.ant-menu {
  background-color: transparent !important;
}

.dragsl {
  cursor: move;
}

b {
  font-weight: 600;
}

.img-responsive {
  max-width: 100%;
  height: auto;
  display: inline-block;
}

.width100 {
  width: 100%;
}

.save-button {
  float: right;
  border-radius: 4px;
}

.text-right {
  text-align: right;
}

.space-between-content {
  display: flex;
  justify-content: space-between;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #e9e9e9;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #e9e9e9;
}

// For page header
.site-page-header {
  background: #fff;
  padding: 24px 0 24px 0;
  margin-bottom: 16px;
}

// Additional Styles for Enhanced Visual Appeal

// Buttons
.ant-btn-primary {
  background-color: var(--color-theme);
  border-color: var(--color-theme);
  &:hover {
    background-color: var(--color-theme-hover);
    border-color: var(--color-theme-hover);
  }
}

.ant-btn-default {
  border-color: var(--color-theme);
  color: var(--color-theme);
  &:hover {
    border-color: var(--color-theme-hover);
    color: var(--color-theme-hover);
  }
}

// Cards
.ant-card {
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  .ant-card-head {
    background-color: var(--color-lightgray);
    border-bottom: 1px solid var(--color-mideumgray);
  }
  .ant-card-body {
    padding: 24px;
  }
}

// Tables
.ant-table {
  border-radius: 8px;
  overflow: hidden;
  .ant-table-thead > tr > th {
    background-color: var(--color-lightgray);
    font-weight: bold;
  }
  .ant-table-tbody > tr > td {
    background-color: #fff;
  }
  .ant-table-tbody > tr:hover > td {
    background-color: var(--color-mideumgray);
  }
}

// Modals
.ant-modal-content {
  border-radius: 8px;
  .ant-modal-header {
    background-color: var(--color-lightgray);
    border-bottom: 1px solid var(--color-mideumgray);
  }
  .ant-modal-footer {
    border-top: 1px solid var(--color-mideumgray);
  }
}

// Forms
.ant-form-item {
  margin-bottom: 16px;
  .ant-form-item-label > label {
    font-weight: bold;
  }
  .ant-form-item-control-input {
    input {
      border-radius: 4px;
    }
  }
}

// Typography
h1, h2, h3, h4, h5, h6 {
  font-family: "Montserrat", Arial, sans-serif; // Added fallback fonts
  font-weight: bold;
}

p {
  font-family: "Montserrat", Arial, sans-serif; // Added fallback fonts
  line-height: 1.6;
}

// Custom Classes
.custom-card {
  background-color: var(--color-white);
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 24px;
}

.custom-header {
  background-color: var(--color-theme);
  color: var(--color-white);
  padding: 16px;
  border-radius: 8px 8px 0 0;
}

.custom-footer {
  background-color: var(--color-lightgray);
  padding: 16px;
  border-radius: 0 0 8px 8px;
}
